import {
  Add,
  Apartment,
  ApartmentOutlined,
  Delete,
  DriveFileRenameOutline,
  Edit,
  EngineeringOutlined,
  Refresh,
  Search,
  Update,
} from "@mui/icons-material";
import { Avatar, Button, CircularProgress, InputBase } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import AddStaffModal from "../../../molecule/AddStaffModal";
import Department from "../../../molecule/Department";
import Staff from "../../../molecule/Staff";
import { select } from "../../../react-select-styles";

const StaffList = () => {
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [openStaffModal, setOpenStaffModal] = useState(false);
  const navigate = useNavigate();
  const [departments, setDepartments] = useState([]);

  const [loader, setLoader] = useState(true);

  const [staff, setStaff] = useState([]);
  const [staff_, setStaff_] = useState([]);

  const [auth, setAuth] = useState(localStorage.getItem('auth') || null)

  const getDepartments = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/department`)
      .then((res) => {
        console.log(res.data);
        setDepartments(
          res.data.map((item, index) => ({ value: item._id, label: item.name }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStaff = () => {
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/staff`)
      .then((res) => {
        console.log(res.data);
        setStaff(res.data);
        setStaff_(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    if(!auth){
      navigate('/login')
    }
    getDepartments();
    getStaff();
  }, []);

  return (
    <div className="grid grid-cols-5 gap-4 bg-[#0A1A32] h-[100vh] ">
      <div className="h-[100vh] bg-[#172D4F] hidden lg:block ">
        <div className="  flex justify-center">
          <div className="w-fit">
            <img
              src="/logo-big.png"
              className="lg:w-full lg:h-full w-[90px] h-[40px] "
              alt="logo"
            />
          </div>
        </div>
        <div className="  flex justify-center w-full">
          <div className="w-full">
            <div
              className="text-left text-white text-[18px] font-[500]  mt-[80px]  py-[26px] pl-16 cursor-pointer "
              onClick={() => navigate("/departments")}
            >
              <ApartmentOutlined
                sx={{ color: "white", position: "relative", bottom: 1 }}
              />{" "}
              Department
            </div>

            <div
              className="text-left text-white text-[18px] font-[500] bg-[#0A1A32] mt-[40px] py-[26px] pl-16  cursor-pointer "
              onClick={() => navigate("/staff")}
            >
              <EngineeringOutlined
                sx={{ color: "white", position: "relative", bottom: 1 }}
              />{" "}
              Staff
            </div>
          </div>
        </div>
      </div>

      <div className="col-span-5 lg:col-span-4 h-[100vh] ">
        <div className="bg-[#172D4F] flex items-center justify-between lg:justify-end py-[16px] space-x-2 lg:space-x-4 px-5 lg:h-[11%] h-[10%] ">
          <div className="flex items-center space-x-2 lg:hidden ">
            <div
              className="text-[12px] text-white  cursor-pointer font-[500] underline "
              onClick={() => navigate("/departments")}
            >
              Departments
            </div>
            <div
              className="text-[12px] text-[#F06247] cursor-pointer font-[500] underline "
              onClick={() => navigate("/staff")}
            >
              Staff
            </div>
          </div>
          <div className="flex items-center  space-x-2 lg:space-x-4 ">
            <div>
              {" "}
              <Avatar
                sx={{
                  width: { lg: "65px", xs: "45px" },
                  height: { lg: "65px", xs: "45px" },
                  bgcolor: "#0A1A32",
                }}
                src="/avatar.jpeg"
              />
            </div>
            <div className=" text-[13px] lg:text-[16px] font-[500] text-white ">
              Admin
            </div>
          </div>
        </div>

        <div className="mt-[53px] lg:pr-4 h-[82%] px-3 lg:px-0 ">
          <div className="flex items-start justify-between h-[15%] ">
            <div>
              <div className="text-[18px] lg:text-[28px]  font-[700] text-white">
                Staff
              </div>
              <div className="text-[13px] lg:text-[18px] font-[500] text-[#B5B5C3]">
                Here are list of of your staff
              </div>
            </div>

            <div>
              <Button
                startIcon={
                  <Add
                    sx={{
                      color: "white",
                      position: { xs: "relative", lg: "normal" },
                      left: 6,
                    }}
                  />
                }
                sx={{
                  textTransform: "none",
                  bgcolor: "#F06247",
                  color: "white",
                  borderRadius: "3px",
                  fontSize: { lg: "16px", xs: "13px" },
                  fontWeight: "600",
                  px: 1,
                  py: 1,
                  ":hover": {
                    bgcolor: "#F06247",
                    color: "white",
                  },
                }}
                onClick={(e) => setOpenStaffModal(true)}
              >
                <div className="hidden lg:block">Add Staff</div>
              </Button>

              <AddStaffModal
                open={openStaffModal}
                setOpen={setOpenStaffModal}
                departments={departments}
                getStaff={getStaff}
              />
            </div>
          </div>

          <div className="mt-[40px] space-y-8 h-[75%] ">
            <div className="flex items-start space-x-4 h-[15%] ">
              <div className="w-full">
                <InputBase
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        color: "white",
                      },
                    },
                  }}
                  sx={{
                    border: "1px solid #B5B5C3",
                    py: 1,
                    px: 2,
                    fontSize: { lg: "18px", xs: "13px" },
                    width: "100%",
                    color: "white",
                  }}
                  startAdornment={<Search sx={{ color: "white" }} />}
                  placeholder="search staff"

                  onChange={(e) => {
                    let str = e.target.value;
                    const regex = new RegExp(str, "i");
                    setStaff(
                      staff_.filter((e) => {
                        let name = e.first_name + e.last_name;
                        if (name.match(regex)) {
                          return e;
                        }
                      })
                    );
                  }}
                />
              </div>

              <div className="border-[1px] border-[rgb(156,163,175,0.6)]  rounded-[0px] mb-3  ">
                <ReactSelect
                  options={departments}
                  value={selectedDepartment}
                  onChange={(option) => {
                    setSelectedDepartment(option);
                    setStaff(
                      staff_.filter((e) => {
                        if (e.department_id === option.value) {
                          return e;
                        }
                      })
                    );
                  }}
                  components={{
                    DropdownIndicator: select.DropdownIndicator,
                    IndicatorSeparator: () => null,
                    Placeholder: select.Placeholder,
                    SingleValue: select.SingleValue,
                  }}
                  styles={select.style}
                  placeholder="select a department"
                />
              </div>
            </div>

            <div className="overflow-x-auto h-[75%] overflow-y-auto scrollmessa">
              <div>
                <Refresh sx={{ color: "white" }} onClick={()=> {
                  setStaff(staff_)
                  setSelectedDepartment(null)
                  }} />
              </div>
              <div className="w-[1000px] lg:w-full ">
                <div className="bg-[#172D4F] p-[30px]   ">
                  <div className="grid grid-cols-5 gap-4 bg-white px-[10px] lg:px-[20px] py-[10px] lg:py-[20px] text-[15px] lg:text-[19px] font-[700] ">
                    <div>Staff</div>
                    <div>Department</div>
                    <div>Role</div>
                    <div>Action</div>
                    <div></div>
                  </div>

                  {loader ? (
                    <div className="flex justify-start mt-5">
                      <CircularProgress
                        thickness={2}
                        sx={{ color: "#F06247" }}
                      />
                    </div>
                  ) : (
                    <>
                      {staff.length > 0 && staff ? (
                        staff.map((item, index) => (
                          <Staff
                            key={index}
                            staff={item}
                            getStaff={getStaff}
                            departments={departments}
                          />
                        ))
                      ) : (
                       <div className="text-white font-semobold  text-[18px]" >🥺 <span className="italic">No staffs here</span> </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffList;

const departments = [{ value: "Marketting", label: "Marketting" }];
