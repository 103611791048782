import { Button, InputBase } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const LoginScreen = () => {
  const [id, setId] = useState("");
  const [pass, setPass] = useState("");
  const navigate = useNavigate();
  const [msg, setMsg] = useState("")

  return (
    <div className="bg-[#0A1A32] h-[100vh] 2xl:h-[100vh] lg:h-fit">
      {/* <img src="/bg.jpg" className=" h-[700px] w-full hidden lg:block" /> */}
      {/* <div
        className="absolute  bg-[#0A1A32]  w-full h-fit top-0
         bg-gradient-to-l from-[rgb(0,0,0,0.2)] to-[rgb(10,26,50,0.5)] "
      /> */}
      <div className=" w-fit">
        <img
          src="/logo-big.png"
          className="lg:w-full lg:h-full w-[90px] h-[40px] "
          alt="logo"
        />
      </div>

      <div className=" top-[12%] lg:top-[14%] w-full">
        <div className="flex justify-center">
          <div className="w-[70vw] bg-white lg:py-[80px] lg:px-[100px] py-4 px-4 ">
            
            <div className="text-center text-[20px] lg:text-[32px] font-[700] ">
              Login
            </div>

            <div className="text-red-600 text-center italic" >{msg}</div>

            <div className="flex justify-center lg:px-[100px] px-2 py-4 lg:mt-[68px] mt-4 ">
              <div className="w-full space-y-6 ">
                <div className="space-y-2 w-full">
                  <div className="lg:text-[18px]  font-[400]">
                    Enter Your ID
                  </div>
                  <div className="w-full">
                    <InputBase
                      sx={{
                        border: "1px solid #0A1A32",
                        py: 1,
                        px: 2,
                        fontSize: { lg: "18px", xs: "16px" },
                        width: "100%",
                      }}
                      placeholder="enter your id here"
                      value={id}
                      onChange={(e) => setId(e.target.value)}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <div className="lg:text-[18px]  font-[400]">Password</div>
                  <div>
                    <InputBase
                      sx={{
                        border: "1px solid #0A1A32",
                        py: 1,
                        px: 2,
                        fontSize: { lg: "18px", xs: "16px" },
                        width: "100%",
                      }}
                      placeholder="enter your password here"
                      value={pass}
                      onChange={(e) => setPass(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex justify-center mt-5">
                  <Button
                    sx={{
                      textTransform: "none",
                      bgcolor: "#F06247",
                      color: "white",
                      borderRadius: "4px",
                      fontSize: { lg: "22px", xs: "18px" },
                      fontWeight: "600",

                      px: 10,
                      py: 1,
                      ":hover": {
                        bgcolor: "#F06247",
                        color: "white",
                      },
                    }}
                    onClick={() => {
                      if (
                        id === "homelifemedia-admin" &&
                        pass === "HomeLife$$$"
                      ) {
                        localStorage.setItem("auth", true);
                        navigate("/departments");
                      }else{
                        setMsg("One or more credentials is incorrect")
                      }
                    }}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
