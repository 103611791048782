import { Cancel } from "@mui/icons-material";
import { Avatar, Button, Dialog, InputBase } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import ReactSelect from "react-select";
import { select } from "../react-select-styles";

const AddStaffModal = ({ open, setOpen, departments, getStaff }) => {
  const [selectedDepartment, setSelectedDepartment] = useState(departments.length > 0 ? departments[0] : null);
  const [previewImg, setPreviewImg] = useState("");

  const [staff, setStaff] = useState({
    first_name: "",
    last_name: "",
    department_id: "",
    department_name: "",
    role: "",
    avatar: null,
    hierarchy: null
  });

  const fileUp = async (staff_id) => {
    console.log(staff_id);
    let formdata = new FormData();
    let file = document.getElementById("image_input").files[0];
    if (file) {
      console.log(file);
      formdata.append("file", file);
      formdata.append("staff_id", staff_id);
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/file`, formdata)
        .then((res) => {
          console.log(res);
          setOpen(false);
          getStaff();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setOpen(false);
      getStaff();
    }
  };

  const addStaff = async () => {

    if(staff.first_name.length < 1 || staff.last_name.length < 1 || staff.role.length < 1|| staff.department_id.length < 1|| staff.department_name.length < 1){
        return 
    }


    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/staff`, staff)
      .then((res) => {
        console.log(res.data);
        fileUp(res.data.newStaff._id);
      })
      .catch((err) => {
        console.log({ err });
        setOpen(false);
        getStaff();
      });
  };
  return (
    <Dialog
      open={open}
      onClose={(e) => setOpen(false)}
      PaperProps={{
        sx: {
          borderRadius: "0px",
        },
      }}
    >
      <div className="bg-[#172D4F] px-4 py-2  ">
        <div className="flex justify-end">
          <Cancel sx={{ color: "white" }} onClick={(e) => setOpen(false)} />
        </div>
        <div className="text-center text-[18px] lg:text-[24px] text-white font-[700] ">
          Add Staff
        </div>
        <div className="flex justify-center mt-[10px] lg:mt-[18px] mb-[5px] lg:mb-[10px] ">
          <div>
            {" "}
            <Avatar
              sx={{
                width: { lg: "65px", xs: "45px" },
                height: { lg: "65px", xs: "45px" },
                bgcolor: "#0A1A32",
              }}
              src={previewImg? previewImg: '/avatar.jpeg'}
            />
          </div>
        </div>

        <div
          className="text-center text-[12px] lg:text-[18px] text-white underline font-[700] cursor-pointer hover:text-[#F06247] "
          onClick={() => {
            document.getElementById("image_input").click();
          }}
        >
          change picture
          <input
            hidden
            type="file"
            accept="image/*"
            id="image_input"
            onChange={(e) => {
              setPreviewImg(URL.createObjectURL(e.target.files[0]));
            }}
          />
        </div>

        <div className="space-y-2 lg:space-y-4 mt-[10px] lg:mt-[20px] ">
          <div className="w-full space-y-1 lg:space-y-2">
            <div className="text-[12px] lg:text-[18px] text-white font-[400px]">
              First Name
            </div>
            <InputBase
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: "white",
                  },
                },
              }}
              sx={{
                border: "1px solid #B5B5C3",
                py: 1,
                px: 2,
                fontSize: { lg: "18px", xs: "13px" },
                width: "100%",
                color: "white",
              }}
              value={staff.first_name}
              onChange={(e) => {
                setStaff((current_items) => ({
                  ...current_items,
                  first_name: e.target.value,
                }));
              }}
              placeholder="e.g Martin"
            />
          </div>
          <div className="w-full space-y-1 lg:space-y-2">
            <div className="text-[12px] lg:text-[18px] text-white font-[400px]">
              Last Name
            </div>
            <InputBase
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: "white",
                  },
                },
              }}
              sx={{
                border: "1px solid #B5B5C3",
                py: 1,
                px: 2,
                fontSize: { lg: "18px", xs: "13px" },
                width: "100%",
                color: "white",
              }}
              placeholder="e.g Riley"
              value={staff.last_name}
              onChange={(e) => {
                setStaff((current_items) => ({
                  ...current_items,
                  last_name: e.target.value,
                }));
              }}
            />
          </div>

          <div className="w-full space-y-1 lg:space-y-2">
            <div className="text-[12px] lg:text-[18px] text-white font-[400px]">
              Department
            </div>
            <div className="border-[1px] border-[rgb(156,163,175,0.6)]  rounded-[0px] mb-3  ">
              <ReactSelect
                options={departments}
                value={selectedDepartment}
                onChange={(option) => {
                  setSelectedDepartment(option);
                  setStaff((current_items) => ({
                    ...current_items,
                    department_id: option.value,
                    department_name: option.label,
                  }));
                }}
                components={{
                  DropdownIndicator: select.DropdownIndicator,
                  IndicatorSeparator: () => null,
                  Placeholder: select.Placeholder,
                  SingleValue: select.SingleValue,
                }}
                styles={select.style}
                placeholder="select a department"
              />
            </div>
          </div>

          <div className="w-full space-y-1 lg:space-y-2">
            <div className="text-[12px] lg:text-[18px] text-white font-[400px]">
              Role In Department
            </div>
            <InputBase
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: "white",
                  },
                },
              }}
              sx={{
                border: "1px solid #B5B5C3",
                py: 1,
                px: 2,
                fontSize: { lg: "18px", xs: "13px" },
                width: "100%",
                color: "white",
              }}
              placeholder="e.g Courier"
              value={staff.role}
              onChange={(e) => {
                setStaff((current_items) => ({
                  ...current_items,
                  role: e.target.value,
                }));
              }}
            />
          </div>


          <div className="w-full space-y-1 lg:space-y-2">
            <div className="text-[12px] lg:text-[18px] text-white font-[400px]">
              Staff Order (hierarchy)
            </div>
            <InputBase
            type='number'
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: "white",
                  },
                },
              }}
              sx={{
                border: "1px solid #B5B5C3",
                py: 1,
                px: 2,
                fontSize: { lg: "18px", xs: "13px" },
                width: "100%",
                color: "white",
              }}
              placeholder="e.g 1"
              value={staff.hierarchy}
              onChange={(e) => {
                setStaff((current_items) => ({
                  ...current_items,
                  hierarchy: e.target.value,
                }));
              }}
            />
          </div>

          
        </div>
      </div>

      <div className="bg-[#0A1A32] py-3 lg:py-3 flex justify-center ">
        <div className="flex space-x-4 ">
          <div>
            <Button
              sx={{
                textTransform: "none",
                bgcolor: "#EB3F3A",
                color: "white",
                borderRadius: "3px",
                fontSize: { lg: "16px", xs: "13px" },
                fontWeight: "600",
                px: 1,
                py: 1,
                ":hover": {
                  bgcolor: "#EB3F3A",
                  color: "white",
                },
              }}
              onClick={(e) => setOpen(false)}
            >
              Cancel
            </Button>
          </div>

          <div>
            <Button
              sx={{
                textTransform: "none",
                bgcolor: "#4598C4",
                color: "white",
                borderRadius: "3px",
                fontSize: { lg: "16px", xs: "13px" },
                fontWeight: "600",
                px: 1,
                py: 1,
                ":hover": {
                  bgcolor: "#4598C4",
                  color: "white",
                },
              }}
              onClick={addStaff}
            >
              Add Staff
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddStaffModal;
