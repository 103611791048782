import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import DepartmentList from './components/screens/dashboard/DepartmentList'
import StaffList from './components/screens/dashboard/StaffsList'
import HomeScreen from './components/screens/HomeScreen'
import LoginScreen from './components/screens/LoginScreen'

const Main = () => {
  return (
    <BrowserRouter>
    <Routes>
        <Route  path='/' element={<HomeScreen/>} />
        <Route  path='/login' element={<LoginScreen/>} />
        <Route  path='/departments' element={<DepartmentList/>} />
        <Route  path='/staff' element={<StaffList/>} />
    </Routes>
    </BrowserRouter>
  )
}

export default Main