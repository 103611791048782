import { Cancel } from "@mui/icons-material";
import { Avatar, Button, Dialog, InputBase } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { select } from "../react-select-styles";

const UpdateDepartmentModal = ({ open, setOpen, dep,  getDepartments }) => {
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [department, setDepartment] = useState();


  console.log(dep)

  useEffect(() => {
    setDepartment(dep)
  }, [dep])
  


  const addDepartmentFunc = async() =>{
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/department/update/`,department)
    .then((res)=>{
        console.log(res.data)
        getDepartments()
        setOpen(false)
        
    })
    .catch((err)=>{
        console.log({err})
        setOpen(false)
    })
  }
  return (
    <Dialog
      open={open}
      onClose={(e) => setOpen(false)}
      PaperProps={{
        sx: {
          borderRadius: "0px",
        },
      }}
    >
      <div className="bg-[#172D4F] px-4 py-2  ">
        <div className="flex justify-end">
          <Cancel sx={{ color: "white" }} onClick={(e) => setOpen(false)} />
        </div>
        <div className="text-center text-[18px] lg:text-[24px] text-white font-[700] ">
          Add Department
        </div>

        <div className="space-y-2 lg:space-y-4 mt-[10px] lg:mt-[20px] ">
          <div className="w-full space-y-1 lg:space-y-2">
            <div className="text-[12px] lg:text-[18px] text-white font-[400px]">
              Department Name
            </div>
            <InputBase
              value={department?.name}
              onChange={(e) => {
                setDepartment((current_items) => ({
                  ...current_items,
                  name: e.target.value,
                }));
              }}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: "white",
                  },
                },
              }}
              sx={{
                border: "1px solid #B5B5C3",
                py: 1,
                px: 2,
                fontSize: { lg: "18px", xs: "13px" },
                width: "100%",
                color: "white",
              }}
              placeholder="e.g Logistics department"
            />
          </div>
          <div className="w-full space-y-1 lg:space-y-2">
            <div className="text-[12px] lg:text-[18px] text-white font-[400px]">
              Department Description
            </div>
            <InputBase
              value={department?.description}
              onChange={(e) => {
                setDepartment((current_items) => ({
                  ...current_items,
                  description: e.target.value,
                }));
              }}
              multiline
              rows={5}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: "white",
                  },
                },
              }}
              sx={{
                border: "1px solid #B5B5C3",
                py: 1,
                px: 2,
                fontSize: { lg: "18px", xs: "13px" },
                width: "100%",
                color: "white",
              }}
              placeholder="enter a description"
            />
          </div>
        </div>
      </div>

      <div className="bg-[#0A1A32] py-3 lg:py-3 flex justify-center ">
        <div className="flex space-x-4 ">
          <div>
            <Button
              sx={{
                textTransform: "none",
                bgcolor: "#EB3F3A",
                color: "white",
                borderRadius: "3px",
                fontSize: { lg: "16px", xs: "13px" },
                fontWeight: "600",
                px: 1,
                py: 1,
                ":hover": {
                  bgcolor: "#EB3F3A",
                  color: "white",
                },
              }}
              onClick={(e) => setOpen(false)}
            >
              Cancel
            </Button>
          </div>

          <div>
            <Button
              sx={{
                textTransform: "none",
                bgcolor: "#4598C4",
                color: "white",
                borderRadius: "3px",
                fontSize: { lg: "16px", xs: "13px" },
                fontWeight: "600",
                px: 1,
                py: 1,
                ":hover": {
                  bgcolor: "#4598C4",
                  color: "white",
                },
              }}
              onClick={addDepartmentFunc}
            >
              Update Department
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateDepartmentModal;
const departments = [{ value: "Marketting", label: "Marketting" }];
