import { Fade } from "@mui/material";
import React, { useEffect, useState } from "react";

const Header = () => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(true);
  }, []);

  window.addEventListener('scroll', (event) => {
    const header = document.getElementById('header')
    if (window.scrollY >= 10) {
      header.style.background = '#0A1A32';
    } else {
      header.style.background = '';
    }
  })

  return (
    <div className="flex items-center  lg:justify-start fixed top-0 w-full lg:py-7 py-2 lg:px-7 px-2 z-50 " id='header' >
      <div>
       <Fade in={checked} timeout={5000} >
       <img src="/logo-big.png" className="lg:w-full lg:h-full w-[90px] h-[40px] " alt="logo" />
       </Fade>
      </div>
    </div>
  );
};

export default Header;
