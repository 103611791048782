import { Avatar } from '@mui/material'
import React from 'react'

const Person = ({staff}) => {
  return (
    <div className="relative">
    <div className="flex justify-center">
    <Avatar src={staff.avatar ? staff.avatar :'/avatar.jpeg'} sx={{ width:{lg:'150px', xs:'100px'}, height:{lg:'150px', xs:'100px'}, bgcolor:'#DEF4FF', position:'relative', top:{lg:'80px', xs:'50px'} }} />
    </div>
    <div className="text-center bg-[#172D4F] pt-[50px] lg:pt-[70px] pb-[20px]" >
        <div className="text-[#F06247] text-[18px] font-[700] mt-6 " >{staff.first_name+" "+ staff.last_name}</div>
        <div className="text-white text-[12px] font-[400] "> {staff.role} </div>
    </div>
</div>
  )
}

export default Person