import {
  Avatar,
  Button,
  CircularProgress,
  InputBase,
  Slide,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import MessageForm from "../../molecule/MessageForm";
import Person from "../../molecule/Person";
import Header from "../layout/Header";

const HomeScreen = () => {
  const [checked, setChecked] = useState(false);
  const [staff, setStaff] = useState([]);
  const [staff_, setStaff_] = useState([]);
  const [active, setActive] = useState(0);

  const [loader, setLoader] = useState(true);

  const [departments, setDepartments] = useState([]);

  const getDepartments = () => {
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/department`)
      .then((res) => {
        console.log(res.data);
        setDepartments(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const getStaff = () => {
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/staff`)
      .then((res) => {
        console.log(res.data);
        setStaff(res.data);
        setStaff_(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getStaff();
    setChecked(true);
    getDepartments();
  }, []);
  return (
    <div>
      <Header />
      <div className="lg:h-[700px] w-full relative">
        <img src="/bg.jpg" className="lg:h-[700px] w-full" />
        <div
          className="absolute bg-transparent w-full h-full top-0
         bg-gradient-to-l from-[rgb(0,0,0,0.4)] to-[rgb(10,26,50,0.8)] "
        />

        <Slide in={checked} direction={"left"} timeout={5000}>
          <div className="absolute z-40 lg:top-[50%] top-[28%] lg:w-2/3 text-white px-7">
            <div className="lg:text-[92px] text-[24px] font-[700] lg:leading-[113px] font-serif ">
              Meet the team
            </div>

            {/* <div className="lg:text-[19px] text-[13px] font-[400] lg:leading-[30px] leading-[20px] font-serif ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida
              eget phasellus metus dui ornare nascetur elit donec sed. Hac
              malesuada sem hendrerit ut nulla euismod. Fermentum vel posuere
              magna scelerisque. Id convallis blandit vestibulum fames.
            </div> */}
          </div>
        </Slide>
      </div>
      <div className="bg-[#0A1A32] mt-[50px] lg:py-[162px] py-3 lg:px-[79px] px-3 ">
        <div className="flex items-center mt-4 w-full overflow-y-auto scrollmessages    ">
          <div className="flex items-center space-x-[50px]">
            <div
              className={
                active === 0
                  ? "bg-[#F06247] border-[1px] border-[#F06247] text-center  min-w-[193px]  py-1 text-[24px] font-[700] cursor-pointer"
                  : " border-[1px] text-white border-[#F06247] text-center  min-w-[193px]  py-1 text-[24px] font-[700] cursor-pointer"
              }
              onClick={() => {
                setActive(0);
                setStaff(staff_);
              }}
            >
              {" "}
              All
            </div>
            {departments && departments.length > 0
              ? departments.map((item, index) => (
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    className={
                      active === index + 1
                        ? "bg-[#F06247] border-[1px] border-[#F06247] text-center w-fit  py-1 px-4   text-[24px] font-[700] cursor-pointer"
                        : " border-[1px] text-white border-[#F06247] text-center w-fit  py-1 px-4 text-[24px] font-[700] cursor-pointer"
                    }
                    key={index}
                    onClick={() => {
                      setActive(index + 1);
                      setStaff(
                        staff_.filter((e) => {
                          if (e.department_id === item._id) {
                            return e;
                          }
                        })
                      );
                    }}
                  >
                    {" "}
                    {item.name}
                  </div>
                ))
              : null}
          </div>
        </div>

        <div className="grid lg:grid-cols-4 gap-6 mt-6">
          {loader ? (
            <div className="flex justify-start mt-5">
              <CircularProgress thickness={2} sx={{ color: "#F06247" }} />
            </div>
          ) : (
            <>
              {staff.length > 0 && staff ? (
                staff.map((item, index) => <Person key={index} staff={item} />)
              ) : (
                <div className="text-white font-semobold  text-[18px]">
                  🥺 <span className="italic">No staffs here</span>{" "}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* <div className="bg-[#0A1A32] mt-[60px] lg:py-[162px] lg:px-[79px] py-4 px-1 "> */}
      {/* <div className="text-center text-white flex justify-center">
          <div className="lg:w-[60vw]">
            <div className="lg:text-[38px] text-[24px] font-[700] leading-[45px] ">
              You can always talk to us here
            </div>
            <div className="lg:text-[18px] text-[14px] font-[400] lg:leading-[30px] leading-[24px]  ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida
              eget phasellus metus dui ornare nascetur elit donec sed. Hac
              malesuada sem hendrerit ut nulla euismod. Fermentum vel posuere
              magna scelerisque. Id convallis blandit vestibulum fames.
            </div>
          </div>
        </div> */}

      {/* <MessageForm /> */}
      {/* </div> */}

      <div className="text-gray-300 font-semibold text-center bg-[#0A1A32]">
        Copyright © 2022 HomeLife
      </div>
    </div>
  );
};

export default HomeScreen;

const person = [1, 2, 3, 4, 5, 6];
