import { Delete, DriveFileRenameOutline } from "@mui/icons-material";
import axios from "axios";
import React, { useState } from "react";
import ConfirmationModal from "./ConfirmationModal";
import UpdateDepartmentModal from "./UpdateDepartmentModal";

const Department = ({dep, getDepartments, staff}) => {
  const [openUpdateDepartmentModal, setOpenUpdateStaffModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [cr, setcr] = useState({
    id: 0,
    department_id:'',
    staff_id:''
  });





  return (
    <div className="bg-[#0A1A32] px-[30px] py-[20px] space-y-[40px] ">
      <div className="flex items-center justify-between">
        <div className="text-white lg:text-[18px] font-[700] ">{dep.name} <span className="text-sm">({staff.length})</span> </div>

        <div className="flex items-center">
          <DriveFileRenameOutline
            sx={{
              color: "white",
              fontSize: { lg: "24px", xs: "20px" },
              cursor: "pointer",
            }}
            onClick={(e) => {
              setOpenUpdateStaffModal(true);
            }}
          />
          <Delete
            sx={{
              color: "white",
              fontSize: { lg: "24px", xs: "20px" },
              ":hover": { color: "red" },
              cursor: "pointer",
            }}
            onClick={() => {
              setConfirmation(true)
              setcr({
                id: 1,
                department_id: dep._id,
                staff_id: null
              })
            }}
          />
          <UpdateDepartmentModal
            open={openUpdateDepartmentModal}
            setOpen={setOpenUpdateStaffModal}
            dep ={dep}
            getDepartments={getDepartments}
          />
          <ConfirmationModal
            open={confirmation}
            setOpen={setConfirmation}
            setcr={setcr}
            cr={cr}
            getDepartments={getDepartments}
          />
        </div>
      </div>

      <div className="text-white font-[300] text-[13px] lg:text-[16px] ">
       {dep.description}
      </div>
    </div>
  );
};

export default Department;
