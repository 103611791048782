import { Cancel } from "@mui/icons-material";
import { Avatar, Button, Dialog, InputBase } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import ReactSelect from "react-select";
import { select } from "../react-select-styles";

const ConfirmationModal = ({ open, setOpen,cr,getDepartments, getStaff }) => {

    const deleteDepartment = (id) =>{
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/department/delete`, {
         id
        })
        .then ((res)=> {
          console.log(res)
          getDepartments()
        })
        .catch((err)=>{
          console.log(err)
          getDepartments()
        })
      }


        const deleteStaff = (id) =>{
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/staff/delete`, {
         id
        })
        .then ((res)=> {
          console.log(res)
          getStaff()
        })
        .catch((err)=>{
          console.log(err)
          getStaff()
        })
      }



  const deleteAction = async () => {
    if(cr.id===1){
        deleteDepartment(cr.department_id)
        console.log('hi')
    }else{
        console.log(cr)
        deleteStaff(cr.staff_id)
  
        
       
    }
  };
  return (
    <Dialog
      open={open}
      onClose={(e) => setOpen(false)}
      PaperProps={{
        sx: {
          borderRadius: "0px",
        },
      }}
    >
      <div className="bg-[#172D4F] px-4 py-2  ">
        <div className="flex justify-end">
          <Cancel sx={{ color: "white" }} onClick={(e) => setOpen(false)} />
        </div>
        <div className="text-center text-[18px] lg:text-[24px] text-white font-[700] ">
          Are you sure you want to delete ?
        </div>

        <div className=" py-3 lg:py-3 flex justify-center ">
          <div className="flex space-x-4 ">

          <div>
              <Button
                sx={{
                  textTransform: "none",
                  bgcolor: "#4598C4",
                  color: "white",
                  borderRadius: "3px",
                  fontSize: { lg: "16px", xs: "13px" },
                  fontWeight: "600",
                  px: 1,
                  py: 1,
                  ":hover": {
                    bgcolor: "#4598C4",
                    color: "white",
                  },
                }}

                onClick={(e)=>{
                    deleteAction()
                }}
              >
                Delete
              </Button>
            </div>


            <div>
              <Button
                sx={{
                  textTransform: "none",
                  bgcolor: "#EB3F3A",
                  color: "white",
                  borderRadius: "3px",
                  fontSize: { lg: "16px", xs: "13px" },
                  fontWeight: "600",
                  px: 1,
                  py: 1,
                  ":hover": {
                    bgcolor: "#EB3F3A",
                    color: "white",
                  },
                }}
                onClick={(e) => setOpen(false)}
              >
                Cancel
              </Button>
            </div>

           
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
