import { Delete } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import React, { useState } from "react";
import ConfirmationModal from "./ConfirmationModal";
import UpdateStaffModal from "./UpdateStaffModal";

const Staff = ({ staff, getStaff, departments }) => {
  const [confirmation, setConfirmation] = useState(false);
  const [openUpdateStaffModal, setOpenUpdateStaffModal] = useState(false);
  const [cr, setcr] = useState({
    id: 0,
    department_id: "",
    staff_id: "",
  });
  return (
    <div className="grid-cols-5 grid gap-4  px-[10px] lg:px-[20px] py-[10px] lg:py-[20px]   ">
      <div className="flex items-center space-x-2 ">
        <Avatar
          sx={{
            width: { lg: "45px", xs: "30px" },
            height: { lg: "45px", xs: "30px" },
            bgcolor: "#0A1A32",
          }}
          src={staff.avatar ? staff.avatar : "/avatar.jpeg"}
        />
        <div className="text-white text-[13px] lg:text-[17px] font-[400] ">
          {staff.first_name + " " + staff.last_name}
        </div>
      </div>

      <div className="text-white  text-[13px] lg:text-[17px]  font-[400] pt-[10px] ">
        {staff.department_name}
      </div>

      <div className="text-white  text-[13px] lg:text-[17px]  font-[400]  pt-[10px] ">
        {staff.role}
      </div>

      <div className="text-white  text-[13px] lg:text-[17px]  font-[400]  pt-[10px] ">
        {departments.length > 0 && (
          <>
            <div
              className="border-[1px] border-[#F06247] text-[14px] w-fit 
      rounded-md p-1 cursor-pointer hover:bg-[#F06247] hover:text-white "
              onClick={(e) => setOpenUpdateStaffModal(true)}
            >
              Update
            </div>
            <UpdateStaffModal
              open={openUpdateStaffModal}
              setOpen={setOpenUpdateStaffModal}
              staf={staff}
              getStaff={getStaff}
              departments={departments}
            />
          </>
        )}
      </div>

      <div className="pt-[10px]">
        <Delete
          sx={{ color: "white", cursor: "pointer", ":hover": { color: "red" } }}
          onClick={() => {
            setConfirmation(true);
            setcr({
              id: 0,
              department_id: null,
              staff_id: staff._id,
            });
          }}
        />

        <ConfirmationModal
          open={confirmation}
          setOpen={setConfirmation}
          setcr={setcr}
          cr={cr}
          getStaff={getStaff}
        />
      </div>
    </div>
  );
};

export default Staff;
